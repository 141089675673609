import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createApp, h} from 'vue';
import App from '@/App'
import { routes as mainRoutes } from './router/mainRouter';
import { routes as aetnaRoutes } from './router/aetnaRouter';
import { routes as mmaRoutes } from './router/mmaRouter';
import { routes as rbcRoutes } from './router/rbcRouter';
import { routes as commonRoutes } from './router/commonRouter';
import { routes as tjuhRoutes } from './router/tjuhRouter';
import { routes as mtabRouter } from './router/mtabRouter';
import { routes as jvRoutes } from './router/jvRouter';
import { routes as kforceRoutes } from './router/kforceRouter';
import { routes as loaRoutes } from './router/loaRouter';
import { routes as ikeaRoutes } from './router/ikeaRouter';
import { routes as demoRoutes } from './router/demoRouter';
import { routes as huntRoutes } from './router/huntingtonRouter';
import { routes as bbRoutes } from './router/bestbuy';
import { data } from '@/shared';

import Toaster from '@meforma/vue-toaster';
import { createPinia } from 'pinia'; 
import { createRouter, createWebHistory } from 'vue-router'
import { createAuth0 } from "@auth0/auth0-vue";
import { nextTick } from 'vue';

require('../src/assets/css/variables.scss');
require('../src/assets/css/fonts.scss');
require('../src/assets/css/style.scss');
require('../node_modules/video.js/dist/video-js.min.css');

  const DEFAULT_TITLE = 'Jellyvision Services & Solutions';
  const app = createApp({
    render: () => h(App),
    el: '#app'
  }); 



  (async () => {
    var addAuth = false;
    var auth0ClientID = '';

    var ssoInfo = JSON.parse(await data.getEnvironmentVariable('ssoClientID')); //works in Heroku
    var ssoDomain = await data.getEnvironmentVariable('ssoDomain'); //API promises
    var properties = await data.getPageProperties();

    var browserTitle = properties != null ? properties.browserTitle : '';

    var host = getQueryVariable("impersonate_url");
    if(host === "") 
      host = window.location.host;

    const parts = (host.indexOf('.') > 0 ? host.split('.') : 0);
    const domainLength = 3; 
    var routes = [];

    if( host.indexOf('localhost') >= 0 || host.indexOf("herokuapp") >= 0 || host.indexOf("test") >= 0)
      routes = mainRoutes;
    else if(parts.length < domainLength || parts[0] === 'www')
      routes = mainRoutes;
    else if(parts[0] === 'ey' || parts[0] === 'wcg' || parts[0] === 'cme' || parts[0] === 'discover')
      routes = loaRoutes;
    else if(parts[0] === 'demo')
      routes = demoRoutes;
    else if(parts[0] === 'dodnafhbp')
      routes = aetnaRoutes;
    else if(parts[0] === 'rbc')
      routes = rbcRoutes;
    else if(parts[0] === 'mma') {
      routes = mmaRoutes;
      auth0ClientID = ssoInfo.mma; 
      addAuth = true;
    }
    else if(parts[0] === 'jefferson') {
      routes = tjuhRoutes;
    }
    else if(parts[0] === 'mtab')
      routes = mtabRouter;
    else if(parts[0] === 'jv') {
      routes = jvRoutes;
      auth0ClientID = ssoInfo.jv;
      addAuth = true;
    }
    else if(parts[0] === 'kforce') {
      routes = kforceRoutes;
    }
    else if(parts[0] === 'ikea') {
      routes = ikeaRoutes;
      auth0ClientID = ssoInfo.ikea;
      addAuth = true;
    }
    else if(parts[0] === 'huntington') {
      routes = huntRoutes;
    }
    else if(parts[0] === 'bestbuy') {
      routes = bbRoutes;
    }
    
    if(browserTitle != '') {
      routes.forEach(route => {
      if(route.meta)
        route.meta.title = browserTitle;
      });
    }

    //adds common pages like timeline, coming soon, and error
    routes.push(...commonRoutes);

    var router = createRouter({
      routes: routes,
      history: createWebHistory()
    });

    router.afterEach((to) => {
      nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
      })
    });

    app.use(router);
    app.use(Toaster).use(createPinia());

    if(addAuth) {
      app.use(
        createAuth0({
          domain: ssoDomain, // process.env.VUE_APP_SSO_DOMAIN,
          client_id: auth0ClientID, // process.env.VUE_APP_SSO_CLIENT_ID,
          redirect_uri: window.location.href
        })
      );
    
    }

    app.mount('#app');

  })();
    

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
      }
  }
  return '';
}
