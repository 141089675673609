<template >
    <div class="feedbackContainer noprint">
        <transition :name="!ShowPagedQuestions ? '' : (back ? 'slide-fade' : 'slide-fade-reverse')">
            <div class="previousButton" v-if="showPrevious && ShowPagedQuestions && !loading" @click="previousQuestion"><i class="fa-solid fa-chevron-left"></i>Previous</div>
        </transition>
        <transition tag="feedback" :name="!ShowPagedQuestions ? '' : (back ? 'slide-fade' : 'slide-fade-reverse')">
            <div class="question" v-if="activeQuestion && !showComment && !loading" :key="activeQuestion">
                <div class="title"> {{activeQuestion.question}}</div>
                <div class="feedbackScoreContainer">
                        <div class="feedbackIconContainer" v-for="icon in activeQuestion.icons" :key="icons.indexOf(icon)"
                                v-on:click="setScore(icon)"
                                v-on:mouseover="focusIcon(icon.score)" v-on:mouseleave="leavePeekScore(icon)">
                            <i class="feedbackIcon" v-bind:class="[icon.class, IsHoverScore(icon.score) ?  'fas' : '', IsScoreSet(icon.score) ? 'feedbackActive' : '']"  />
                            <div class="feedbackScoreText" v-show="IsHoverScore(icon.score) || IsScoreSet(icon.score) || AlwaysShowImageText">{{icon.text}}</div>
                        </div>
                </div>
            </div>
        </transition>
        <transition tag="feedback" :name="back ? 'slide-fade' : 'slide-fade-reverse'">
            <div class="feedbackCommentContainer" v-show="ShowCommentBox && (showComment || !ShowPagedQuestions) && !loading">
                <div class="title" v-if="ShowPagedQuestions" v-html="CommentsQuestionText"></div>
                <textarea v-model="comment" :placeholder="placeholderText"></textarea>
            </div>
        </transition>
        <div class="feedbackButtonContainer" v-show="RequireSubmitButton && (showSubmit || !ShowPagedQuestions) && !loading">
            <button v-on:click="saveFeedback" :disabled="submitted">{{submitButtonText}}</button>
        </div>
        <div class="submittedFeedbackContainer" v-show="submitted">
            <span>{{ thankYouMessage }}</span>            
        </div>
        <div class="pageCounter" v-if="totalPages > 1 && !submitted && ShowPagedQuestions">
            {{activePage}} / {{totalPages}}
        </div>
    </div>
</template>

<script>
import { data } from "../../shared";

export default {
    name: "Feedback",
    emits: ["resetControl"],
    props: {
        questions: Array,
        thankYouMessage: String,
        submitFeedbackButtonText: {
            type: String,
            default: "SEND MY FEEDBACK"
        },
        IsModal: {
            type: Boolean,
            default: false
        },
        ShowCommentBox: {
            type: Boolean, 
            default: true
        },
        CommentsQuestionText: {
            type: String, 
            default: "Any other feedback to help us improve this page?"
        },
        AlwaysShowImageText: {
            type:Boolean,
            default: false
        },
        RequireSubmitButton: {
            type:Boolean,
            default: true
        },
        feedbackActive: {
            type: Boolean
        },
        //will only show the first question
        ShowPagedQuestions: {
            type: Boolean,
            default: true
        },
        icons: {
            type: Array,
            default: function() {
                return [
                        {score: 1, class: 'far fa-grimace', text:'Missed the mark'},
                        {score: 2, class: 'far fa-frown', text: 'Not helpful'},
                        {score: 3, class: 'far fa-meh', text: "I'm not sure"},
                        {score: 4, class: 'far fa-smile', text: 'Helpful'},
                        {score: 5, class: 'far fa-grin-stars', text: 'Extremely helpful'}
                    ];
            }
        }
    },
    data() {
        return {
            comment: "",
            submitted: false,
            hoverScore : -1,
            activeQuestion: null,
            answers: [],
            activeQuestionIndex: -1,
            showComment: false,
            showPrevious: false,
            showSubmit: false,
            loading: false,
            activePage: 0,
            totalPages: 0,
            back: false
        };
    },
    computed: {
        submitButtonText: function() {
            return this.submitted ? "SENT" : this.submitFeedbackButtonText; 
        },
        placeholderText: function() {
            return this.ShowPagedQuestions ? '' : this.CommentsQuestionText;
        }
    },
    mounted() {
        this.totalPages = this.questions.length;
        if(this.ShowCommentBox)
            this.totalPages += 1;
        this.nextQuestion();
    },
    methods: {
        nextQuestion: function() {
            if(!this.activeQuestion)
            {
                this.activeQuestionIndex = 0;
                this.activeQuestion = this.questions[this.activeQuestionIndex];
            }
            else {
                this.activeQuestionIndex++;
                let ques = this.questions[this.activeQuestionIndex];
                this.hoverScore = -1;
                if(ques) {
                    this.activeQuestion = ques;
                    this.activeQuestion.score = this.answers[this.activeQuestionIndex];
                    this.back = false;
                    this.loadTimeout();
                } else if(this.ShowCommentBox) {
                    this.showComment = true;
                    this.showSubmit = true;
                    this.back = false;
                    this.loadTimeout();
                } else if(!this.RequireSubmitButton)
                    this.saveFeedback();
                this.showPrevious = true;
            }
            if(!this.activeQuestion.icons)
                this.activeQuestion.icons = this.icons;
            if((this.questions.length === 1 || (this.questions.findIndex(q => q.sequence === this.activeQuestion.sequence) === this.questions.length - 1)) && !this.ShowCommentBox) {
                this.showSubmit = true;
                this.back = true;
                this.loadTimeout();
            }
            this.activeQuestion.score = null;
            this.activePage += 1;
        },
        previousQuestion: function() {
            if(this.showComment) {
                this.activeQuestionIndex--;
                this.hoverScore = -1;
                this.activeQuestion = this.questions[this.activeQuestionIndex]; // this.questions.find(q => q.sequence === this.activeQuestion.sequence - 1);
                this.activeQuestion.score = this.answers[this.activeQuestionIndex];
                if(!this.activeQuestion.icons)
                    this.activeQuestion.icons = this.icons;
                this.showComment = false;                
                this.back = true;
                this.loadTimeout();
            } else {
                this.activeQuestionIndex--;
                this.activeQuestion = this.questions[this.activeQuestionIndex]; // this.questions.find(q => q.sequence === this.activeQuestion.sequence - 1);
                this.activeQuestion.score = this.answers[this.activeQuestionIndex];
                this.back = true;
                this.loadTimeout();
                if(!this.activeQuestion.icons)
                    this.activeQuestion.icons = this.icons;
            }
            if(this.questions.findIndex(q => q.sequence === this.activeQuestion.sequence) === 0) {
                this.showPrevious = false;
                this.back = true;
                this.loadTimeout();
            }
            if(this.showSubmit && this.RequireSubmitButton)
                this.showSubmit = false;
                
            this.activePage -= 1;
        },
        loadTimeout: function() {
            this.loading = true
            setTimeout(function(){
                this.loading = false;
            }.bind(this), 400);
        },
        leavePeekScore: function() {
            this.hoverScore = -1;
        },
        focusIcon: function(score) {
           this.hoverScore = score;

        },
        IsHoverScore: function(score){
            return this.hoverScore === score && !this.IsScoreSet(score);
        },
        IsScoreSet: function(score) {
            return this.activeQuestion.score == score;
        },
        setScore: function(icon) {
            if(this.submitted) return;
            this.activeQuestion.score = icon.score;
            this.answers[this.activeQuestionIndex] = icon.score;
            if(this.ShowPagedQuestions) this.nextQuestion();
        },
        saveFeedback: async function() {
            if(this.submitted) return;
            var sent_comment = false;
            for(var i = 0; i < this.questions.length; i++)
            {
                let postBody = {
                    feedbackScore: this.answers[i],
                    feedbackComment: !sent_comment ? this.comment : "",
                    feedbackSubjectCode: this.questions[i].subject
                };

                data.postFeedback(postBody);

                sent_comment = true;
            }

            this.submitted = true;
            
            if(this.RequireSubmitButton && this.IsModal )
                setTimeout(function() { this.resetFeedbackModal() }.bind(this) , 3000);
        },
        resetFeedbackModal: function() {
            this.activeQuestion = null;
            this.comment = "";
            this.submitted = false;
            this.hoverScore = -1;
            this.showComment = false;
            this.showPrevious = false;
            this.showSubmit = false;
            this.$emit("resetControl");
        }
    },
}
</script>
<style scoped>
.feedbackContainer {
     display:flex; 
     flex-direction: column;
}

.feedbackContainer .question {
    display: flex;
    flex-direction: column;
}

.feedbackContainer div {
    display: flex;
}

.feedbackScoreContainer {
    flex-direction: row; 
}

.feedbackIconContainer {
    flex-direction: column; 
    flex: 1;
}

.feedbackIconContainer .feedbackIcon {
    display: flex;
    transition: all .3s ease-out;
}

.feedbackCommentContainer {
    display: flex;
    flex-direction: column;
}

.feedbackCommentContainer .title {
    margin-bottom: 40px;
}

.feedbackContainer .previousButton {
    position: absolute;
    top: 40px;
    cursor: pointer;
}

.feedbackContainer .previousButton i {
    margin-right: 5px;
}

.feedbackContainer .pageCounter {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

feedback {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.slide-fade-enter-active {
	transition: all .3s ease;
}
.slide-fade-leave-active {
	transition: all .3s ease;
}
.slide-fade-enter-from {
	transform: translateX(-100px);
	opacity: 0;
}

.slide-fade-leave-to {
	transform: translateX(100px);
	opacity: 0;
}

.slide-fade-reverse-enter-active {
	transition: all .3s ease;
}
.slide-fade-reverse-leave-active {
	transition: all .3s ease;
}
.slide-fade-reverse-enter-from {
	transform: translateX(100px);
	opacity: 0;
}

.slide-fade-reverse-leave-to {
	transform: translateX(-100px);
	opacity: 0;
}

</style>
