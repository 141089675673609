function setComponent(){
    var host = getQueryVariable("impersonate_url");
    if(host === "") 
      host = window.location.host;

    const parts = (host.indexOf('.') > 0 ? host.split('.') : 0);

    if(parts.length > 0) {
        if(parts[0] === 'discover')
            return () => import('../components/Discover/LeaveOfAbsence.vue');
        if(parts[0] === 'wcg')
            return () => import('../components/WCG/LeaveOfAbsence.vue');
        if(parts[0] === 'ey')
            return () => import('../components/EY/LeaveOfAbsence.vue');
        if(parts[0] === 'cme')
            return () => import('../components/CME/LeaveOfAbsence.vue');
    }
}

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return '';
  }


export const routes = [
  {
    path: '/',
    redirect: { name: 'Default'},
  },
  {
    path: '/loa',
    name: 'Default',
    component: setComponent(),
    meta: {
      title: 'Leave of Absence Portal'
    }
  },
];
