<template>
  <div v-if="accordion && accordion.headers && accordion.headers.length > 0">
    <div class="accordionHeader">
      <div v-for="header in accordion.headers" :key="header.id">
        <img v-if="header.mediaProperty && header.mediaProperty.mediaType === 'Image'" :src="header.mediaProperty.configuration.src" />
        <div v-if="header.displayText && !header.mediaProperty">
          <DisplayText :cssClass="header.cssClass" :displayText="header.displayText"></DisplayText>
        </div>
      </div>
    </div>
  </div>
  <div
    id="timeline"
    v-if="accordion"
    class="post-1230 page type-page status-publish hentry"
  >
    <h1 v-if="this.displayTitle == true">{{ accordion.name }}</h1>
    <dl class="accordion">
      <template v-for="section in accordion.sections" :key="section.id">
        <dt
          v-on:click="setSelected(section)"
          :class="getSelected(section)"
        >
          <span v-for="header in section.headers"
             v-bind:key="header.id">
             {{ header.minimizedText }}
          </span>
        </dt>
        <dd
          :class="getSelected(section)"
        >
          <p
            v-for="detail in section.details"
            v-bind:key="detail.id"
            :class="(detail.cssClass === '' || detail.cssClass === 'accordionDetailText') && (detail.mediaProperty == null || detail.mediaProperty.mediaType =='ToolTip')  ? 'accordionDetailText' : ''"
          >
            <span v-if="detail.displayText && !detail.mediaProperty">
              <DisplayText :cssClass="detail.cssClass !== 'accordionDetailText' ? detail.cssClass : ''" :displayText="detail.displayText"></DisplayText>
            </span>
            <video-player v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'Video'" 
                :mediaPropertyID="detail.mediaPropertyID" :options="detail.mediaProperty.configuration" 
                :tracks="detail.mediaProperty.configuration.tracks" :ref="'video' + detail.id"
                :videoDescription="detail.mediaProperty.description"></video-player>
            <img v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'Image'" :src="detail.mediaProperty.configuration.src" />
            <Checklist v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'Checklist'" :options="detail.mediaProperty.configuration" :client="client" :ref="'checklist' + detail.id" />
            <SampleLanguageBox v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'SampleLanguage'" :options="detail.mediaProperty.configuration" :client="client" :ref="'sample' + detail.id" />
            <BarChart v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'BarChart'" :options="detail.mediaProperty.configuration" :client="client" id="'barchar' + detail.id" />
            <span style="display: inline" v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'ToolTip'" v-html="detail.preText"></span>
            <ToolTip v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'ToolTip'" :text="detail.mediaProperty.configuration.text">{{ detail.middleText }}</ToolTip>
            <span style="display: inline" v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'ToolTip'" v-html="detail.postText"></span>
            <Table v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'Table'" :options="detail.mediaProperty.configuration"></Table>
          </p>
        </dd>
      </template>
    </dl>
  </div>
  <div v-if="accordion && accordion.footers">
    <div v-for="footer in accordion.footers" :key="footer.key" class="accordion-footer">
      <img v-if="footer.mediaProperty && footer.mediaProperty.mediaType === 'Image'" :src="footer.mediaProperty.configuration.src" />
      <div v-if="footer.displayText && !footer.mediaProperty">
        <DisplayText :cssClass="footer.cssClass" :displayText="footer.displayText"></DisplayText>
      </div>
    </div> 
  </div>
  <Navigation v-if="accordion && playableTracks.length" :onGoToPreviousPage="goToPrevious" :onGoToNextPage="goToNext" :showAudioPlayer="true"
                        :showGoToNextPage="false" :showGoToPreviousPage="false" :audioTracks="audioTracks" :playTrackId="currentTrackId"></Navigation>
  </template>

<script>
import { data } from "@/shared";
import VideoPlayer from '@/components/VideoPlayer.vue';
import BarChart from '@/components/shared/BarChart';
import Checklist from '@/components/shared/Checklist';
import SampleLanguageBox from '@/components/shared/SampleLanguageBox';
import ToolTip from '@/components/shared/ToolTip';
import Table from '@/components/shared/Table';
import DisplayText from '@/components/shared/DisplayText';
import { audio } from '@/shared/audio';
import Navigation from '../shared/Navigation.vue';
import jsonLogic from 'json-logic-js';

export default {
  components: { VideoPlayer, Checklist, SampleLanguageBox, BarChart, ToolTip, Table, DisplayText, Navigation}, 
  name: "Accordion",
  props: {
    accordionCode: String, 
    displayTitle: Boolean, 
    client: String,
    hasDisplayLogic: Boolean, 
    logicParameters: Object,
    replacements: Object,
    onAccordionLoad: Function
  },
  data() {
    return {
      accordion: null,
      selectedId: -1,
      currentTrackId: '',
      playableTracks: [],
      storageBaseUrl: ''
    };
  },
  computed: {
    audioTracks() {
        if(this.playableTracks.length > 0 && this.accordion.actions.length > 0) {
          var tracks = [];
          var actions = this.accordion.actions.filter((action) => 
            this.isAudioAction(action) && jsonLogic.apply(JSON.parse(action.conditionalJson), this.logicParameters)
          );
          
          actions.sort((a, b) => {
              return a.sequence - b.sequence;
          });
          
          for(var i = 0; i < actions.length; i++) {
            tracks.push(this.playableTracks.find((search) => { return actions[i].configuration.mediaPropertyID.toString() === search.id} ) );
          }

          return tracks;
        }
        else 
          return [];
    },
  },
  async mounted() {
    if(!this.hasDisplayLogic || this.logicParameters)
      this.getAccordion(this.accordionCode, this.logicParameters, this.replacements);
  },
  methods: {
    getAccordion: async function(code, parameters, replacementStrings) {
      let accordion = await data.getAccordion(code, parameters);
      const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

      const replacementKeys = replacementStrings ? Object.keys(replacementStrings) : null;

      let length = accordion.sections.length;
      accordion.sections.forEach((element) => {
        accordion.sections.push({ ...element, visible: false });
        element.details.forEach((det) => {
          if((!det.mediaPropertyID || det.mediaProperty.mediaType === 'ToolTip') && replacementStrings)
          {
            replacementKeys.forEach((replacementKey) => {
              det.displayText = det.displayText.replaceAll(replacementKey, replacementStrings[replacementKey]);
            });
          }

          if(det.mediaPropertyID && det.mediaProperty.mediaType === 'ToolTip') {
              const toolTipStart = det.displayText.indexOf('<ToolTip');
              const middleTextStart = det.displayText.search(/(<ToolTip>)/) + 9;
              const middleTextEnd = det.displayText.indexOf('</ToolTip>');
              const toolTipEnd = det.displayText.indexOf('</ToolTip>') + 10;
              det.preText = det.displayText.substr(0, toolTipStart);
              det.postText = det.displayText.slice(toolTipEnd, det.displayText.length);
              det.middleText = det.displayText.slice(middleTextStart, middleTextEnd);
          }

          if(det.mediaPropertyID && det.mediaProperty.mediaType === 'Image' 
              && det.mediaProperty.configuration.isLocalPath === false) {
            det.mediaProperty.configuration.src = storageBaseUrl + det.mediaProperty.configuration.src;            
          }

          if(det.mediaPropertyID && det.mediaProperty.mediaType === 'Video') {
            if(det.mediaProperty.configuration.poster)
                this.videoConfig.poster = storageBaseUrl + this.videoConfig.poster;

            det.mediaProperty.configuration.sources.forEach(sc => {
                sc.src = storageBaseUrl + sc.src;
            });

            
            det.mediaProperty.configuration.tracks.forEach(tk => {
                tk.src = storageBaseUrl + tk.src;
            });
          }
        });
      });

      for (var i = 0; i < length; i++) {
        accordion.sections.shift();
      }

      
      if(accordion.headers) {
        for (i = 0; i < accordion.headers.length; i++) {
          if(replacementKeys) {
            replacementKeys.forEach((replacementKey) => {
              accordion.headers[i].displayText = accordion.headers[i].displayText.replaceAll(replacementKey, replacementStrings[replacementKey]);
            });
          }
          if(accordion.headers[i].mediaProperty && accordion.headers[i].mediaProperty.mediaType === 'Image')
            accordion.headers[i].mediaProperty.configuration.src = await data.getMediaFile(storageBaseUrl + accordion.headers[i].mediaProperty.configuration.src);
        }
      }

     if(accordion.footers) {
        for (i = 0; i < accordion.footers.length; i++) {
          if(replacementKeys) {
            replacementKeys.forEach((replacementKey) => {
              accordion.footers[i].displayText = this.footers.headers[i].displayText.replaceAll(replacementKey, replacementStrings[replacementKey]);
            });
          }
          if(accordion.footers[i].mediaProperty && accordion.footers[i].mediaProperty.mediaType === 'Image')
            accordion.footers[i].mediaProperty.configuration.src = await data.getMediaFile(storageBaseUrl + accordion.footers[i].mediaProperty.configuration.src);
        }
      }
      if(this.onAccordionLoad)
        this.onAccordionLoad(accordion);
      
      this.loadAudioTracks(accordion);
      
      this.accordion = accordion;
    },
    setSelected: function(section) {
      if(this.accordion.isOnlyOneActiveSection) {
        if(this.selectedId === section.id) {
          this.selectedId = -1;
        } else {
          this.selectedId = section.id;
        }
      } else {
        section.selected = !section.selected;
      }
      section.details.forEach((detail) => {
        if(detail.mediaProperty && 
        detail.mediaProperty.mediaType === 'Video' && 
        detail.mediaProperty.configuration.autoPlay && 
        (this.selectedId !== -1 || section.selected)) {
          let ref = 'video' + detail.id;
          this.$refs[ref][0].autoPlayStart();
        }
      })
    },
    getSelected: function(section) {
      if(this.accordion.isOnlyOneActiveSection) {
        if(section.id === this.selectedId) {
          return 'active';
        }
      } else {
        if(section.selected) {
          return 'active';
        }
      }
    },
    goToPrevious: function() {
      if(this.activeSectionIndex > 0)
        this.activeSectionIndex--;
      else
        this.onAccordionEnd(false, this.returnToSummary);
    },
    goToNext: function() {
      if(this.activeSectionIndex < (this.accordion.sections.length - 1))  
        this.activeSectionIndex++;
      else
        this.onAccordionEnd(true, this.returnToSummary);
    },
    async loadAudioTracks(accordion) {
      if(accordion.actions.length)
        this.playableTracks = await audio.loadAudioTracks(accordion.actions);
    },
    isAudioAction(action) {
      return action.type === 'PlayAudio';
    },
  },
  watch: {
    '$route.params.code': function(code) {
      this.getAccordion(code);
    },
    audioTracks() {
      if(this.audioTracks.length) 
          this.currentTrackId = this.audioTracks[0].id;
    }
}
};
</script>

<style lang="scss" scoped src="@/assets/css/shared/tooltip/tooltip_standard.scss"></style>
<style lang="scss" scoped src="@/assets/css/shared/table/table_styling.scss"></style>