import MTABBenefitsLounge from '@/components/MTAB/BenefitsLounge';

export const routes = [
  {
    path: '/',
    redirect: "lounge"
  },
  {
    path: '/lounge',
    component: MTABBenefitsLounge,
    meta: {
      title: 'MTAB Benefits Lounge'
    }
  },
];
