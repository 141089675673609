<template>
    <div class="informationPage">
        <div class="contentContainer">
            <div class="topBar">
                <div class="sectionTitle">
                    <h5>{{config.sectionTitle}}</h5>
                </div>
                <div class="backButton noprint" @click="onClickStartOver">
                    <i class="fa-solid fa-angles-left"></i> Start Over
                </div>
                <div class="printButton noprint" @click="print()">
                    <i class="fa-solid fa-print"></i> Print
                </div>
                <SendEmailModal type="PrintPage" v-if="showEmail"
                                elementId="infoAccordion"
                                sender="Jellyvision Custom <info@jellyvisioncustom.com>"
                                subject="Results"
                                attachmentName="LOA Information Results.pdf"
                                :template="emailTemplate"
                                :templateVariables=emailTemplateVariables
                                :inlineImages="emailImages"
                                :openModalText="emailOpenModalText"
                                templateType="LoaResults"
                                ></SendEmailModal>
            </div>
            <div id="infoAccordion">
                <Accordion v-if="config.accordionCode" 
                            :accordionCode="config.accordionCode" 
                            :displayTitle="false" 
                            :hasDisplayLogic="true"
                            :logicParameters="$parent.piniaSurvey.getAttributes"
                            :replacements="replacements" ></Accordion>
            </div>
        </div>
    </div>
</template>

<script>
import { data } from '@/shared';
import Accordion from '@/components/shared/Accordion';
import SendEmailModal from "@/components/shared/SendEmailModal";
require('@/assets/css/shared/send_email/send_email_variables_standard.scss');

export default {
    components: { Accordion, SendEmailModal },
    name: 'LoaInformationPage',
    data() {
        return {
            emailTemplateVariables: {
                first_name: '',
                logo_src: '',
                return_url: window.location.href
            },
            emailImages: [],
            showEmail: false
        }
    },
    props: {
        config: Object,
        onClickStartOver : {
            type: Function
        },
        corporateLogoLocation: {
            type: String,
            default: null
        },
        emailTemplate: {
            type: String 
        },
        replacements: {
            type: Object
        },
        emailOpenModalText: {
            type: String, 
            default: ""
        }
    },
    methods: {
        print: function() {
            window.print();
        }
    },
    beforeMount() {
        this.accordionHeaderIcon = this.config.accordionHeaderIcon ? this.config.accordionHeaderIcon : "/images/circle-plus.svg";
        this.accordionHeaderIconHover = this.config.accordionHeaderIconHover ? this.config.accordionHeaderIconHover : "/images/circle-plus.svg";
        this.accordionHeaderIconActive = this.config.accordionHeaderIconActive ? this.config.accordionHeaderIconActive : "/images/circle-minus.svg";
    },
    async mounted() {
        this.$parent.piniaSurvey.setAttributes(null);
        const fileStorageUrl = await data.getEnvironmentVariable('fileStorageUrl');

        if(this.corporateLogoLocation)
        {
            var lastDot = this.corporateLogoLocation.lastIndexOf(".");
            if(lastDot > 0 && lastDot + 1 < this.corporateLogoLocation.length)
            {                  
                var extension = this.corporateLogoLocation.substr(lastDot + 1);

                this.emailTemplateVariables.logo_src = 'cid:logo.' + extension;
                this.emailImages.push({
                    url: fileStorageUrl + this.corporateLogoLocation,
                    name: 'logo.' + extension
                });
                const pageProperties = await data.getPageProperties();
                if(pageProperties.loaProperties && pageProperties.loaProperties.hasEmail) {
                    this.showEmail = true;
                }
            }           
        }
    }
}
</script>
<style lang="scss" scoped src="@/assets/css/shared/send_email/send_email_modal.scss"></style>
<style lang="scss" scoped>
    :deep(.accordion dt) {
        background: v-bind('"url(" + accordionHeaderIcon + ") no-repeat 10% 50%," + config.accordionHeaderBackground');
        color: v-bind('config.accordionHeaderTextColor ? config.accordionHeaderTextColor : "#ffffff"');
    }
    :deep( .accordion dt:hover) {
        background: v-bind('"url(" + accordionHeaderIconHover + ") no-repeat 10% 50%," + config.accordionHeaderBackgroundHover');
        color: v-bind('config.accordionHeaderTextColorHover ? config.accordionHeaderTextColorHover : "#ffffff"');
    }
    :deep(.accordion dt.active) {
        background: v-bind('"url(" + accordionHeaderIconActive + ") no-repeat 10% 50%," + config.accordionHeaderBackgroundActive');
        color: v-bind('config.accordionHeaderTextColorActive ? config.accordionHeaderTextColorActive : "#ffffff"');
    }
    .informationPage .sectionTitle {
        color: v-bind('config.sectionTitleColor');
    }
</style>