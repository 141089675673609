<template>
  <div id="app1"></div>
  <router-view />
</template>

<script>
import markerSDK from '@marker.io/browser';
import { data }  from './shared';

export default {
  name: 'App',
  mounted() {
    this.updateIcon();
    this.loadMarkerSDK();
  },
  methods: {
    async loadMarkerSDK() {
      var properties = await data._getSessionData(data.enumSessionFields.pageProperties);
      if(!properties || !properties.feedbackProperties) return;

      var feedbackGuid = this.getQueryVariable("feedbackCode");
      if(feedbackGuid === '') return;
      
      if(properties.feedbackProperties.length) {
        properties.feedbackProperties.forEach(async fp => {
          if (fp.feedbackGUID === feedbackGuid) {
            const widget = await markerSDK.loadWidget({
              project: fp.projectID         
            });

            widget.setCustomData = JSON.parse('{"a":1}');
            widget.show();
            return;
          }
        });
      }       
    },
    updateIcon() {
      var host = this.getQueryVariable("impersonate_url");
      if(host === "") 
        host = window.location.host;
      const parts = (host.indexOf('.') > 0 ? host.split('.') : 0);
      const favicon = document.getElementById("favicon");
      if( host.indexOf('localhost') >= 0)
        favicon.href = '/favicon.png';
      else if(parts[0] === 'www')
        favicon.href = '/favicon.png';
      else if(parts[0] === 'ey-loa')
        favicon.href = '/favicon.png';
      else if(parts[0] === 'dodnafhbp')
        favicon.href = '/favicon.png';
      else if(parts[0] === 'demo') {
        if(window.location.pathname === '/offboarding')
          favicon.href = '/images/Demo/alex-favicon.png'
        else
          favicon.href = '/favicon.png';
      }
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              return decodeURIComponent(pair[1]);
          }
      }
      return '';
    }
  }
}
</script>
<style>
    .loa_body {
        display: flex;
        min-height: 100vh;
    }

    .loa_body #app {
        display: flex;
       flex: 1 1 auto;
    }    
</style>