import * as axios from 'axios';
import config from '../../config';
import Enum from 'enum';
import { useGuidStore } from "@/stores/sessionId";

const enumSessionFields = new Enum(['deviceType', 'deviceName', 'userAgent', 'clientCode',
         'hostname', 'pageProperties', 'definedValues', 'logoAssetID', 'pathName']);
const enumEnvironmentVariableFields = new Enum (['apiUrl', 'fileStorageUrl', 'ssoClientID', 'ssoDomain', 'jvApiKey']);

const getEnvironmentVariable = async function(varName) {
    let enVars = sessionStorage.getItem('enVars');
    if(enVars) {
        enVars = JSON.parse(enVars);
        const currentTime = new Date(); 
        if(enVars.expire > currentTime.getTime() && enVars[varName]) {
            return enVars[varName];
        } else {
            sessionStorage.removeItem('enVars');
        }
    }
    try { 
        let resp = await jvGet('/enVars', null, false);
        const currentTime = new Date();
        resp.data.expire = currentTime.getTime() + (60*60*1000);
        sessionStorage.setItem('enVars', JSON.stringify(resp.data));
        if(resp.data[varName])
            return resp.data[varName];
        else
            RedirectToError();
    }
    catch(error) {    
        const myURL = new URL(location.href);
        if(myURL.hostname === "localhost") {
            if(enumEnvironmentVariableFields.apiUrl.is(varName))
                return config.apiUrl;
            else if(enumEnvironmentVariableFields.fileStorageUrl.is(varName))
                return config.fileStorageUrl;
            else if(enumEnvironmentVariableFields.ssoDomain.is(varName))
                return config.ssoDomain;
            else if(enumEnvironmentVariableFields.ssoClientID.is(varName))
                return config.ssoClientID;
        }
        else {
            console.error(error);
            postErrorLog(error);
            RedirectToError();
        }
    }
}

const getAccordionParameters = async function(code) {
    try{
        const response = await jvGet("/api/jellyroll/" + code);
        const parameters = response.data;
        return parameters;
    }
    catch(error){
        console.error(error);
        postErrorLog(error);
        RedirectToError();
    }
}

const getAccordion = async function(code, parameters) {
    var params = null;
    if(parameters != null)
        params = new URLSearchParams([['displayParams',  JSON.stringify(parameters)]]);
    
    const response = await jvGet("/api/accordion/GetByCode/" + code, { params });
    const accordion = response.data;
    return accordion;
};

const getAccordionByID = async function(id, parameters) {
    var params = null;
    if(parameters != null)
        params = new URLSearchParams([['displayParams',  JSON.stringify(parameters)]]);

    params["returnActiveRecordsOnly"] = true;
    
    const response = await jvGet("/api/accordion/" + id, { params });
    const accordion = response.data;
    return accordion;
};
const postFeedback = async function(feedbackObj, useGuidForLogging = true) {
    if(useGuidForLogging === true) {
        const guidStore = useGuidStore();
        feedbackObj.sourceId = guidStore.getSessionId();
        feedbackObj.sourceType = 'GUID'; 
    }   

    feedbackObj.clientCode = await _getSessionData(enumSessionFields.clientCode);
    return await jvPost("/api/feedback/", feedbackObj, false);
};

const postActivityLog = async function(activityObj, useGuidForLogging = true) {
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    var skipLogging = !!params.get("skip_logging");
    
    if(skipLogging)
        return;

    if(useGuidForLogging === true) {
        const guidStore = useGuidStore();
        activityObj.sourceId = guidStore.getSessionId();
        activityObj.sourceType = 'GUID'; 
    }    

    activityObj.deviceType = await _getSessionData(enumSessionFields.deviceType);
    activityObj.deviceName = await _getSessionData(enumSessionFields.deviceName);
    activityObj.userAgent = await _getSessionData(enumSessionFields.userAgent);
    activityObj.clientCode = await _getSessionData(enumSessionFields.clientCode);
    const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl) + '/api/activityLog';
    const blob = new Blob([JSON.stringify(activityObj)], { type: 'application/json' });
    navigator.sendBeacon(baseUrl, blob);
}

const postErrorLog = async function(error) {
    await jvPost('/postErrorLog', error, false, false);
}

const getMediaFile = async function(fileUrl) {
    const resp = await jvGet('/mediaFile?fileUrl=' + fileUrl, null, false);
    return resp.data;
}

const getMediaFileProperties = async function(fileUrl) {
    const resp = await jvGet('/mediaFileProperties?fileUrl=' + fileUrl, null, false);
    if(resp.data && resp.success === true) {
        if(resp.data.ContentType === 'image/gif') 
            resp.data.LastModifiedTicks = convertToTicks(new Date());
        else 
            resp.data.LastModifiedTicks =  convertToTicks(resp.data.LastModified); 
        return resp.data;
    }
    else 
        return {};
}

const convertToTicks = function(dateTime) {
      // .NET Ticks start from 0001-01-01, while JavaScript Date starts from 1970-01-01
      const jsTicks = 621355968000000000;
      const msToTicks = 10000;

      // Convert JavaScript Date object to milliseconds since the epoch
      const dt = new Date(dateTime);

      const dateTimeMs = dt.getTime();

      // Convert milliseconds to .NET ticks
      return jsTicks + (dateTimeMs * msToTicks);
}

const _getSessionData = async function(eSessionField) {
    let sessionData = sessionStorage.getItem('sessionData');
    if(sessionData)
        sessionData = JSON.parse(sessionData);

    let host = await getUrl();

    if(sessionData && sessionData[enumSessionFields.hostname] === host && 
                    sessionData[enumSessionFields.pathName] === window.location.pathname) {
                        console.log("Using Session" );
        const currentTime = new Date();
        if(sessionData.expire > currentTime.getTime() && sessionData[eSessionField])
            return sessionData[eSessionField];
        else
            sessionStorage.removeItem('sessionData');
    }

    try {
        let params = (new URL(document.location)).searchParams;
        // let resp = await axios.get('/sessionData');
         let resp = await jvGet('/sessionData?hostName=' + window.location.hostname + '&url=' + window.location.pathname + '&impersonatedUrl=' + params.get("impersonate_url"), null, false);
        const currentTime = new Date();
        resp.data.expire = currentTime.getTime() + (60*60*1000);
        resp.data[enumSessionFields.pathName] = window.location.pathname;
        sessionStorage.setItem('sessionData', JSON.stringify(resp.data));
        if(resp.data[eSessionField])
            return resp.data[eSessionField];
        else
            return null;
    }
    catch(error) {
        if(error.response.status === 404 && (enumSessionFields.deviceType.is(eSessionField) || enumSessionFields.deviceName.is(eSessionField) ||
                enumSessionFields.userAgent.is(eSessionField)))
            return 'Other';
        else {
            console.error(error);
            postErrorLog(error);
            return undefined;
        }
    }
}

const getClientCode = async function() {
    return await _getSessionData(enumSessionFields.clientCode);
}

const getPageProperties = async function() {
    return await _getSessionData(enumSessionFields.pageProperties);
}

const getClientConstants = async function() {
    return await _getSessionData(enumSessionFields.definedValues);
}

const getClientLogoAssetID  = async function() {
    return await _getSessionData(enumSessionFields.logoAssetID);
}

const getUrl = async function() {
    const myURL = new URL(location.href);
    let params = new URLSearchParams(myURL.searchParams);

    if(myURL.hostname === "localhost" || myURL.hostname === "jv-custom-solutions-web-dev.herokuapp.com") {
        let returnUrl = params.get("impersonate_url");
        return (returnUrl != null) ? returnUrl : myURL.hostname;
    }
    else 
        return myURL.hostname;
}

const getSurvey = async function(surveyId) {
    const response = await jvGet("/api/survey/" + surveyId);
    const survey = response.data;
    return survey;
}

const getSurveyByCode = async function(surveyCode) {
    const response = await jvGet("/api/survey/GetByCode/" + surveyCode);
    const survey = response.data;
    return survey;
}

const getReplacementStringAttributes = async function() {
    const response = await jvGet("/api/attribute?replacement_strings_only=true");
    return response.data;
}

const RedirectToError = async function() {
    const myURL = new URL(location.href);
    if(myURL.hostname !== "localhost")
        window.location.href = '/ErrorPage';
    else 
        alert("an error has occurred. See console");
    
}

const getMediaProperty = async function(id) {
    const response = await jvGet("/api/mediaproperty/" + id);
    const mediaProperty = response.data;
    return mediaProperty;
}

const postIdomoo = async function(configuration) {
    try {
        const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
        var response =  await axios.post(baseUrl + "/api/idomoo/", configuration);

        if(response.status === 200 )
        {
            return {
                checkStatusUrl: response.data.check_status_url,
                videoUrl: response.data.output.video[0].links.url
            };
        }
        else 
            throw "Idomoo request failed";
    }
    catch(error){
        console.error(error);
        postErrorLog(error);
        return undefined;
    }
}

const checkIdomooStatus = async function(statusUrl) {
    const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
    const response = await axios.get(baseUrl + "/api/idomoo/GetStatus?checkStatusUrl=" + statusUrl);

    if(response.data.status === "VIDEO_AVAILABLE")
        return true;
    else 
        return false;
}

const saveClientData = async function(id, name, type, clientData) {
    var postData = {
        'clientDataName': name,
        'clientCode': await _getSessionData(enumSessionFields.clientCode),
        'clientDataRecordType': type,
        'clientDataConfiguration': clientData
    }

    var returnData = {
        'id': id,
        'errors': null,
        'success': false
    }

    let response;
    if(id) {
        response = await jvPatch('/api/clientdata/' + id, postData);
    } else {
        response = await jvPost('/api/clientdata/', postData);
    }
    returnData.id = response.id;
    returnData.errors = response.errors;
    returnData.success = response.success;

    return returnData;
}

const ProcessSaveErrors = function (errors) {

    var retErrors = []
    for (var key in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
            var val = errors[key];
            for(var j = 0; j < val.length; j++)
            {
                retErrors.push(key + ' ' + val[j]);
            }
        }
    }
    return retErrors;
}
const loadClientData = async function(id) {
    const clientCode = await _getSessionData(enumSessionFields.clientCode);
    const response = await jvGet("/api/clientdata/" + id + "?clientCode=" + clientCode);
    return response.data;
}

const browseClientData = async function(type, searchText, activeOnly) {
    const clientCode = await _getSessionData(enumSessionFields.clientCode);
    if(!activeOnly)
        activeOnly = true;
        
    const response = await jvGet("/api/clientdata?client_code=" + clientCode +
        "&client_data_record_type=" + type + "&search_text=" + searchText + "&active_only=" + activeOnly);
    return response.data;
}

const deleteClientData = async function(id) {
    const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
    const clientCode = await _getSessionData(enumSessionFields.clientCode);

    const response = await axios.delete(baseUrl + "/api/clientdata/" + id + "?clientCode=" + clientCode);
    return response.data;
}

const postSingleFileToWasabiMMA = async function(file, path) {    
    let formData = new FormData();
    formData.append('file', file);

    let filename = path.split('/').pop();

    const response = await jvPost('/mmaSingleFile?filename=' + filename, formData, true, false, {headers: {'Content-Type': 'multipart/form-data'}});
    return response.data.location;
}

const postEmail = async function(emailObj) {
    let formData = new FormData();
    for(let i = 0; i < emailObj.attachments.length; i++) {
        formData.append('attachments', emailObj.attachments[i]);
    }
    for(let i = 0; i < emailObj.inlineImages.length; i++) {
        formData.append('inlineImages', emailObj.inlineImages[i]);
    }
    formData.append('from', emailObj.sender);
    formData.append('to', emailObj.recipients);
    formData.append('subject', emailObj.subject);
    formData.append('text', emailObj.text);
    formData.append('html', emailObj.html);
    formData.append('template', emailObj.template || '');
    formData.append('templateType', emailObj.templateType || 'NotSet');
    formData.append('templateVariablesJson', emailObj.templateVariablesJson);
    const response = await jvPost('/api/email/SendEmail', formData, true, true, {headers: {'Content-Type': 'multipart/form-data'}});
    const messageId = response.data;
    return messageId;
}

const getLmsUrl = async function(learnerId, courseId) {    
    var returnData = {
        'url': "",
        'errors': null
    }

    const response = await jvGet("/api/lms?learnerID=" + learnerId + "&courseID=" + courseId);
    returnData.url = response.data;

    return returnData;
}

const getIkeaApiInfo = async function(employeeID) {    
    const apikey = await getEnvironmentVariable(enumEnvironmentVariableFields.jvApiKey);
   
    const response = await jvGet("/api/Ikea/GetEmployeeInfo/" + employeeID + "?apiKey=" + apikey);
    return response.data;
}

const Sleep = async function(milliseconds) {    
    const response = await jvGet("/api/JV/Sleep/" +milliseconds );
    return response.data;
}

const jvPatch = async function(uri, saveObject, redirectOnError = true, addBaseUrl = true, configObject = null) {
    var result = {
        success: null,
        errors: null, 
        id: saveObject.id
    }; 
    let apiUrl = '';
    if(addBaseUrl) {
        const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
        apiUrl += baseUrl;
    }
    apiUrl += uri;
    let counter = 0;
    while(result.success === null) {
        try {
            await axios.patch(apiUrl, saveObject, configObject)
                        .then( () => { result.success = true;})
        } catch(error) {
            if(error.code === 'ECONNABORTED' && counter < 4)
                counter++;
            else {
                postErrorLog(error);
                let params = new URLSearchParams(uri);
                if(params.get("debug"))
                    console.error(error);
                else if((!error.response || error.response.status !== 422) && redirectOnError)
                    RedirectToError();
                if(error.response)
                    result.errors = ProcessSaveErrors(error.response.data.errors);
                result.success = false;
            }
        }
    }

    return result;
}

const jvPost = async function(uri, saveObject, redirectOnError = true, addBaseUrl = true, configObject = null) {
    var result = {
        success: null,
        errors: null, 
        id: null,
        data: null
    }; 
    let apiUrl = '';
    if(addBaseUrl) {
        const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
        apiUrl += baseUrl;
    }
    apiUrl += uri;
    let counter = 0;
    while(result.success === null) {
        try {
            await axios.post(apiUrl, saveObject, configObject)
                .then((res) => { 
                    result.success = true; 
                    result.id = res.data.id; 
                    if(res.data)
                        result.data = res.data;
                });
        } catch(error) {
            if(error.code === 'ECONNABORTED' && counter < 4)
                counter++;
            else {
                postErrorLog(error);
                let params = new URLSearchParams(uri);
                if(params.get("debug"))
                    console.error(error);
                else if((!error.response || error.response.status !== 422) && redirectOnError)
                    RedirectToError();
                if(error.response)
                    result.errors = ProcessSaveErrors(error.response.data.errors);
                result.success = false;
            }
        }
    }

    return result;
}

const jvGet = async function(uri, queryObj = null, addBaseUrl = true, redirectOnError = true, configObject = null) {
    var result = {
        success: null,
        errors: null,
        data: null
    };
    let apiUrl = '';
    if(addBaseUrl) {
        const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
        apiUrl += baseUrl;
    }
    apiUrl += uri;
    let counter = 0;
    while(result.success === null) {
        try {
            await axios.get(apiUrl, queryObj, configObject)
            .then( (resp) => { result.data = resp.data; result.success = true;})
        } catch(error) {
            if(error.code === 'ECONNABORTED' && counter < 4)
                counter++;
            else {
                postErrorLog(error);
                let params = new URLSearchParams(uri);
                if(params.get("debug"))
                    console.error(error);
                else if((!error.response || error.response.status !== 422) && redirectOnError)
                    RedirectToError();
                if(error.response)
                    result.errors = ProcessSaveErrors(error.response.data.errors);
                result.success = false;
            }
        }
    }

    return result;
}

export const data = {
    getAccordion,
    getAccordionByID,
    postFeedback,
    postActivityLog,
    postErrorLog,
    getAccordionParameters,
    getEnvironmentVariable,
    getMediaFile,
    getMediaFileProperties,
    convertToTicks,
    enumEnvironmentVariableFields,
    enumSessionFields,
    getUrl,
    getClientCode,
    getPageProperties,
    getSurvey,
    getSurveyByCode,
    getMediaProperty,
    postIdomoo,
    checkIdomooStatus,
    saveClientData,
    loadClientData,
    browseClientData,
    deleteClientData,
    postSingleFileToWasabiMMA,
    postEmail,
    getLmsUrl,
    _getSessionData,
    getReplacementStringAttributes,
    getClientConstants,
    getClientLogoAssetID,
    getIkeaApiInfo,
    Sleep
};