import aetnamapd from '@/components/KForce/videos';

export const routes = [
  {
    path: '/',
    redirect: { name: 'Default'}
  },
  {
    path: '/videos',
    name: 'Default',
    component: aetnamapd,
    meta: {
      title: 'KForce Videos'
    }
  },
  {
    path: '/videos/:videoName',
    component: aetnamapd,
    meta: {
      title: 'KForce Videos'
    }
  }
];
