import POC  from '@/components/Huntington/POC';

export const routes = [
  {
    path: '/',
    name: 'Default',
    component: POC,
    meta: {
      title: 'Huntington'
    }
  },
  {
    path: '/poc',
    component: POC,
    meta: {
      title: 'Huntington'
    }
  }

];
