import aetnamapd from '@/components/Aetna/aetnamapd';

export const routes = [
  {
    path: '/',
    name: 'Default',
    component: aetnamapd,
    meta: {
      title: 'Aetna Medicare Advantage Videos'
    }
  },
  {
    path: '/aetnamapd',
    component: aetnamapd,
    meta: {
      title: 'Aetna Medicare Advantage Videos'
    }
  }
];
